<template>
    <b-card title="Return Order Invoice Check Order Type Exception" sub-title="Enabling DO with Order type on the list to skip Invoice Verification">
      <b-modal v-model="addModal" title="Add Order Type Exception">
        <b-container fluid>
          <b-row class="mb-1">
            <b-col>
              <label for="moduleSelect">Input Order Type</label>
                <b-form-input
                v-model="orderTypeForm"
                type="search"
                placeholder="Add Order Type"
              />
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button 
              variant="success" 
              class="ml-1" 
              @click="addOrderType"
              :disabled="tableLoading"
              >Confirm</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="addModal = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-modal v-model="modalDelete" title="Delete Order Type">
        <!-- Alert before Delete-->
        <b-container>
          <b-row>
            <b-col cols="12" >
              <div class="d-flex">
                Are you sure want to delete this order type ?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
          </div>
        </template>
      </b-modal>
      <b-col cols="12">
        <b-row class="mt-2">
          <b-col md="4"> 
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Search by Order Type"
              style="float: right;"
            />
          </b-col>
          <b-col cols="4" md="auto">
            <b-btn variant="primary" @click="searchByDO()" class="text-nowrap">
              Search
            </b-btn>
          </b-col>
        </b-row>
        <b-row class="justify-content-end flex-wrap">
          <b-col cols="12" md="auto" class="mb-1">
            <b-btn variant="success" @click="add()" class="text-nowrap">
              Add Order Type
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="table-responsive">
            <b-table
              :items="orderType"
              :fields="fields"
              :busy.sync="tableLoading"
              show-empty
            >
              <template #cell(actions)="row">
                <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                  <template #button-content>
                    <feather-icon size="1x" icon="MenuIcon" />
                  </template>
                  <b-dropdown-item style="text-align:center">
                    <b-button @click="openModal(row.item._id)" variant="danger" class="mr-1">
                      <feather-icon icon="Trash2Icon"/>
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
  
          <b-col cols="12">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              style="float: right"
            />
          </b-col>

        </b-row>
      </b-col>
    </b-card>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import axios from '@/axios';
  import {userAccess, dateFormat} from '@/utils/utils'
  
  export default {
    data() {
      return {
        downloadModal:false,
        fields: [
          { key: 'orderType', label: 'Order Type Exception', filterable: true, sortable: true },
          { key: 'createdAt', label: 'Created At' },
          { key: 'createdBy', label: 'Created By'},
          { key: 'actions', label: 'Actions' },
        ],
        search: '',
        currentPage: 1,
        perPage: 10,
        totalRows: 1,
        tableLoading: false,
        addModal: false,
        modalDelete : false,
        selectedModule: null,
        startDate: null,
        endDate: null,
        orderType : [],
        orderTypeForm : "",
        moduleOptions: ["Edit DO Status", "Disapproved Return"],
        selectedId: ""
      }
    },
    mounted() {
      
    },
    methods: {
      searchByDO(){
        this.currentPage=1;
        this.getOrderTypeException({ 
          page: this.currentPage, 
          entry: this.perPage,
          orderType: this.search
        })
      },
      onPageChange(page) {
        //let filter = this.getFilter();
        console.log("page", page);
        this.getOrderTypeException({ 
          page: page, 
          entry: this.perPage,
        })
      },
      processMetadata(metadata) {
        let { totalData } = metadata;
        console.log('total data ',totalData)
        this.totalRows = totalData;
        this.tableLoading = false;
      },
      getOrderTypeException(filter) {
        axios.get(`sales-and-purchase/drcc/order-type-exception`, { params: { ...filter } }).then((response) => {
          const formattedData = response.data.data.map(item => {
          // Mendapatkan tanggal dan waktu dari item.createdAt
          const date = new Date(item.createdAt);

          // Mendapatkan komponen tanggal dan waktu
          const day = date.getDate();
          const month = date.getMonth() + 1; // Ingat bahwa bulan dimulai dari 0
          const year = date.getFullYear();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const seconds = date.getSeconds();

          // Format tanggal dan waktu sesuai kebutuhan
          const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}, ${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;

          // Mengembalikan objek dengan createdAt yang diformat
          return {
              ...item,
              createdAt: formattedDate
          };
        });
          console.log( "response", response);
          this.processMetadata(response.data.metadata)
          this.orderType=formattedData
          return response.data;
        }).catch((e) => {
          console.log(e);
        })
      },
      add(){
        this.addModal = true
      },
      addOrderType(){
        if(!this.orderTypeForm){
          this.$bvToast.toast("Please add Order Type First", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return ;
        } 
        axios.post(`sales-and-purchase/drcc/order-type-exception`, { orderType : this.orderTypeForm}).then((response) => {
          this.$bvToast.toast("Add Order Type Success", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.addModal = false
          this.getOrderTypeException({ 
            page: this.currentPage, 
            entry: this.perPage,
          })
        }).catch((e) => {
          console.log(e);
          this.$bvToast.toast(e.response, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
      },
      openModal(deleteId){
        console.log("deleteId", deleteId);
        this.selectedId = deleteId
        this.modalDelete = true
      },
      confirmDelete() { 
        this.modalDelete = false
        console.log("this.selectedId", this.selectedId);
        axios.delete(`/sales-and-purchase/drcc/order-type-exception/${this.selectedId}`).then(() => {
          this.$bvToast.toast(`You have successfully deleted post code`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getOrderTypeException({ 
            page: this.currentPage, 
            entry: this.perPage,
          })
        }).catch((e) => {
          this.$bvToast.toast(`You have failed to delete postcode`, {
            title: "Fail",
            variant: "danger",
            solid: true,
          });
        })
      },
    },
    computed: {
      permission() {
        return userAccess("Order Type Exception", "utilitytools_menu");
      },
    },
    created() {
      document.title = 'Order Type Exception | RSP';
      this.getOrderTypeException({
        page: this.currentPage, 
        entry: this.perPage,
      })
    },
  }
  </script>
  <style scoped>
  @media (min-width: 1423px) {
    .hide-on-mobile { display: none !important; }
    .show-on-desktop {display: inline !important;}
  }
  
  @media (max-width: 1422px) {
    .hide-on-desktop { display: none !important; }
  }
  </style>